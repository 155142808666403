
export const useMetrika = () => {
    if (process.server) {
        return {
            goal: () => { },
            ecommerce: () => { }
        };
    }

    return {
        goal: (name: string) => {
            if (!useRuntimeConfig().public.isDomino) {
                return;
            }

            try {
                ym(94782852, 'reachGoal', name);
            } catch (error) { }
        },

        ecommerce: (actionType: string, products: any[], actionField: any = {}, eventType: string) => {
            if (!useRuntimeConfig().public.isDomino) {
                return;
            }

            try {
                window.dataLayer = window.dataLayer || [];

                let data: any = {
                    "event": eventType,
                    "ecommerce": {
                        "currencyCode": "RUB",
                    }
                };

                data["ecommerce"][actionType] = {
                    "actionField": actionField,
                    "products": products
                };

                window.dataLayer.push(data);
            } catch (error) { }

        }
    };
};